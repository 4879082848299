// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "./iframeSizer.contentWindow.min.js"
import "./jquery.hideShow.min.js"

import "../stylesheets/main.scss"
import "../stylesheets/tailwindcss/compiled.scss"

import close_svg from "../../assets/images/icons/close.svg";

global.$ = jQuery

require("jquery")

import "../../../node_modules/jquery-ui-sortable/jquery-ui.min.js"

require("selectize")

Rails.start()
ActiveStorage.start()

var my_spinner

const ANIMATION_SPEED = 200

function scroll_to(element) {
  $([document.documentElement, document.body]).animate({
    scrollTop: element.offset().top
  }, ANIMATION_SPEED)
}

function scroll_to_inner_element(element, inner_element) {
  element.animate({
    scrollTop: inner_element.offset().top - element.offset().top + element.scrollTop() - element.outerHeight() / 2 + inner_element.outerHeight() / 2,
    scrollLeft: inner_element.offset().left - element.offset().left + element.scrollLeft() - element.outerWidth() / 2 + inner_element.outerWidth() / 2,
  }, ANIMATION_SPEED)
}

window.clear_recommended_resources = function clear_recommended_resources() {
  $("#contact_form .recommended-resources .resources-list").text("")
}

window.selected_recommended_resource_ids = function selected_recommended_resource_ids() {
  return $("#contact_form").serializeArray().filter(param => param.name == "contact[recommended_resource_ids][]").map(param => parseInt(param.value))
}

window.initialize_selectize = function initialize_selectize() {
  $("select.selectize:not(.selectized)").each(function (_index, select) {
    const $select = $(select)

    $select.selectize({
      create: $select.is("[data-create]"),
    })
  })
}

function external_selectize_change(selects) {
  selects.each(function (_index, select) {
    const selectize = select.selectize
    select = $(select)
    const selected_options = select.find("option[selected]")
    const control = select.next(".selectize-control")
    const items_list = $(control.next(".selectize-external-item-list")[0] || control.after('<div class="selectize-external-item-list">').next()[0])

    items_list.html([...selected_options.map(function (_index, option) {
      return `
        <span class="selectize-external-item">
          ${$(option).html()}
          <span data-selectize-remove-option="${$(option).val()}">
            <img src="${close_svg}" />
          </span>
        </span>
      `
    })].join(""))

    items_list.find("[data-selectize-remove-option]").click(function () {
      if (select.is("[disabled]")) {
        return;
      }

      selectize.removeItem($(this).data("selectize-remove-option"))
      selectize.refreshItems()
    })
  })
}

function validate_form(form) {
  let result = true

  const inputs = form.find("input, select, textarea").filter(":visible")

  inputs.each(function (_index, input) {
    if (!input.checkValidity()) {
      result = false
      input.reportValidity()
    }
  })

  return result
}

window.set_last_payload = function set_last_payload (form) {
  form.data("last-payload", form.serialize())
}

function on_step_change(step_item) {
  let steps = step_item.closest(".steps")
  let status_bar_items = steps.find(".steps-status-bar .steps-status-bar-item")

  status_bar_items.removeClass("active")
  status_bar_items.filter("[data-step-index=" + step_item.data("step-index") + "]").addClass("active")

  $("[data-hide-on-step-change]").hide()
  $("[data-show-on-step-change]").show()
}

function go_to_step(step_item) {
  const message = window.onbeforeunload()
  if (message) {
    alert(message)
    return;
  }

  if (!step_item.length) {
    return;
  }

  const step_items = step_item.closest(".steps").find("> .step")
  step_items.removeClass("active")
  step_item.addClass("active")
  on_step_change(step_item)
}

function badges_for(values) {
  return values.map(value => `<span class="badge">${value}</span>`).join("")
}

function sync_input_watcher(input) {
  const context = $("body").first()
  const input_name = input.attr("name")
  const input_group = context.find("[name='" + input_name + "']")
  const watchers = context.find(".input-watcher[data-input-name='" + input_name + "']")

  let value;

  if (input.is("[type=checkbox]")) {
    value = badges_for([...input_group.filter(":checked").map((_i, i) => $(i).val())])
  }
  else if (input.is("[multiple=multiple]")) {
    value = badges_for(input.val())
  }
  else {
    value = input.val()
  }

  watchers.html(`
    <div class="flex flex-wrap gap-1">
      ${value}
    </div>
  `)
}

function manage_caller_details() {
  const form = $("form:has(.someone-else)")
  const elements = form.find(".someone-else")
  const elements_to_hide = form.find(".someone-else-hidden")

  if (form.find("#contact_yourself_or_someone_else").val() == "Someone else") {
    elements.show()
    elements_to_hide.hide()
  }
  else {
    elements.hide()
    elements_to_hide.show()
  }
}

window.initialize_scroll_effect = function initialize_scroll_effect(scroll_effect_elements) {
  scroll_effect_elements = scroll_effect_elements.filter(":not(.scroll-effect-initialized)")
  scroll_effect_elements.each(function (_index, element) { manage_scroll_effect(element) })
  scroll_effect_elements.hideShow().on("scroll visibilityChanged DOMSubtreeModified", function () { manage_scroll_effect(this) })
  scroll_effect_elements.addClass("scroll-effect-initialized")
}

function manage_scroll_effect(scroll_effect_element) {
  scroll_effect_element = $(scroll_effect_element)
  const scroll_effect_wrapper = scroll_effect_element.closest(".scroll-effect-wrapper")

  function set_effect_opacity(element, size_function, difference) {
    if (!element.length) {
      return
    }

    const size = element[size_function]()

    let opacity = - size + difference
    if (opacity > 0) opacity = 0
    opacity = 1 - Math.abs(opacity / size)
    element.css({ opacity })
  }

  set_effect_opacity(
    scroll_effect_wrapper.find("> .scroll-effect-left"), "outerWidth", scroll_effect_element.scrollLeft()
  )

  set_effect_opacity(
    scroll_effect_wrapper.find("> .scroll-effect-right"), "outerWidth", scroll_effect_element[0].scrollWidth - scroll_effect_element.innerWidth() - scroll_effect_element.scrollLeft()
  )

  set_effect_opacity(
    scroll_effect_wrapper.find("> .scroll-effect-top"), "outerHeight", scroll_effect_element.scrollTop()
  )

  set_effect_opacity(
    scroll_effect_wrapper.find("> .scroll-effect-bottom"), "outerHeight", scroll_effect_element[0].scrollHeight - scroll_effect_element.innerHeight() - scroll_effect_element.scrollTop()
  )
}

function manage_image_uploader(input_element) {
  const file = (input_element.files || [])[0];
  const image_preview = $(input_element).closest(".image-uploader").find(".image-preview")
  const no_file = image_preview.find(".no-file")

  let background_image = image_preview.data("initial-background-image")

  if (!background_image) {
    image_preview.data("initial-background-image", image_preview.css("background-image"))
  }

  if (file) {
    background_image = `url(${URL.createObjectURL(file)})`
    no_file.hide()
  }
  else {
    no_file.show()
  }

  image_preview.css({"background-image": background_image})
}

$(document).ready(function () {
  initialize_scroll_effect($(".scroll-effect"))

  $(".image-uploader [type=file]").change(function () { manage_image_uploader(this) })

  const sidebar = $(".sidebar").first()
  let sticky_sidebar_enabled = true

  if (sidebar.filter(":visible").length && sticky_sidebar_enabled) {
    let last_scroll_top = 0
    let last_direction

    sidebar.css({ position: "absolute" })

    function disable_sticky_sidebar() {
      if (!last_direction) return;

      last_scroll_top = 0
      last_direction = null

      sidebar.next().css("padding-left", "0")
      sidebar.css({ position: "relative", top: "unset", bottom: "unset" })
    }

    function adjust_sticky_sidebar() {
      if ($(window).width() < 768) {
        disable_sticky_sidebar()
        return;
      }

      sidebar.next().css("padding-left", sidebar.width() + "px")

      let scroll_top = $(window).scrollTop()
      let direction = last_scroll_top <= scroll_top ? "down" : "up"
      let sidebar_offset = sidebar.offset()
      let margin = parseInt(sidebar.parent().css("padding-top"))

      if (direction != last_direction) {
        sidebar.css({ position: "absolute", top: sidebar_offset.top, bottom: sidebar_offset.bottom })
      }

      if (direction == "down" && sidebar_offset.top + sidebar.height() < scroll_top + $(window).height() - margin) {
        sidebar.css({ position: "fixed", top: "unset", bottom: margin })
      }
      else if (direction == "up" && sidebar_offset.top > scroll_top + margin) {
        sidebar.css({ position: "fixed", top: margin, bottom: "unset" })
      }

      if (!last_direction) {
        sidebar.css({ position: "absolute", top: Math.max(scroll_top, margin), bottom: "unset" })
      }

      last_scroll_top = scroll_top
      last_direction = direction
    }

    adjust_sticky_sidebar()

    $(window).on("scroll resize", adjust_sticky_sidebar)
  }

  my_spinner = $("#component_samples > .spinner").html()

  $("[data-scroll-to]").each((_index, element) => {
    element = $(element)
    const inner_element = element.find(element.data("scroll-to"))

    scroll_to_inner_element(element, inner_element)
  })

  $("[data-watchable]").change(function () {
    sync_input_watcher($(this))
  })

  $("[data-watchable]").each(function (_index, input) {
    sync_input_watcher($(input))
  })

  initialize_selectize()

  external_selectize_change($("select[multiple].selectize-external-items"))

  $("select[multiple].selectize-external-items").change(function () {
    external_selectize_change($(this))
  })

  const resource_form = $("#resource_form").first()

  if (resource_form.filter(".new-form").length && resource_form.find("#resource_suggestions").length) {
    resource_form.find("#resource_resource_name, #resource_email").on("keyup", function () {
      if ($(this).val().length && $(this).val().length < 3) {
        return;
      }

      $.get(
        "/resources/duplicate_suggestions.js",
        {
          resource_name: resource_form.find("#resource_resource_name").val(),
          email: resource_form.find("#resource_email").val(),
          iframe: resource_form.find("#iframe").val(),
        },
      )
    })
  }

  const provider_type_select = $("#resource_provider_type, #filters_provider_type")

  if (provider_type_select.length) {
    function manage_resource_fields() {
      if (!resource_form.length)
        return;

      const fields = resource_form.find("[data-field-name]")
      const hide_class = "!hidden"

      fields.removeClass(hide_class)

      Object.entries(resource_fields_data).forEach(field_data => {
        const field_name = field_data[0]
        const field = fields.filter(`[data-field-name='${field_name}']`)
        const input = field.find("input").first()
        const label = field.find("label[data-initial-value]").first()
        field_data = field_data[1]

        if (field_data.only && !field_data.only.includes(provider_type_select.val())) {
          field.addClass(hide_class)
        }
        else if (field_data.except && field_data.except.includes(provider_type_select.val())) {
          field.addClass(hide_class)
        }

        if (field.parent().find(`> :not(.\\${hide_class})`).length) {
          field.parent().removeClass(hide_class)
        }
        else {
          field.parent().addClass(hide_class)
        }

        let label_text
        if (field_data.label_overrides && Object.keys(field_data.label_overrides).includes(provider_type_select.val())) {
          label_text = field_data.label_overrides[provider_type_select.val()]
        }
        else {
          label_text = label.data("initial-value")
        }

        if (input.attr("placeholder") == label.html()) {
          input.attr("placeholder", label_text)
        }

        if (label.data("required")) {
          label_text = `${label_text} *`
        }

        label.html(label_text)
      })
    }

    function provider_type_change() {
      const resource_types_selectize = $("#resource_resource_types, #filters_resource_types")[0].selectize
      const old_values = resource_types_selectize.getValue() || []
      const options = resource_types[provider_type_select.val()] || []
      const new_values = options.filter(option => old_values.includes(option))

      if (!new_values.length) {
        resource_types_selectize.setValue(null)
      }

      resource_types_selectize.clearOptions()

      options.forEach(option => { resource_types_selectize.addOption({ value: option, text: option }) })

      resource_types_selectize.setValue(new_values)

      manage_resource_fields()
    }

    provider_type_select.change(provider_type_change)

    provider_type_change()
  }

  const safe_forms = $("form.safe-form")

  safe_forms.each(function (_index, form) {
    set_last_payload($(form))
  })

  safe_forms.on("ajax:success", function () {
    set_last_payload($(this))
  })

  window.onbeforeunload = function () {
    const unsaved_forms = safe_forms.filter(function (index) {
      const form = $(safe_forms[index])
      return form.data("last-payload") != form.serialize()
    })

    if (unsaved_forms.length) {
      return "Please save your changes first!"
    }
  }

  manage_caller_details()

  $("form:has(.someone-else)").change(manage_caller_details)

  const contact_form = $("#contact_form").first()

  if (contact_form.length) {
    if (contact_form.is(".new-form")) {
      contact_form.find("#contact_name, #contact_phone, #contact_email").on("keyup", function () {
        if ($(this).val().length && $(this).val().length < 3) {
          return;
        }

        $.get(
          "/contacts/duplicate_suggestions.js",
          {
            name: contact_form.find("#contact_name").val(),
            phone: contact_form.find("#contact_phone").val(),
            email: contact_form.find("#contact_email").val(),
          },
        )
      })
    }

    const contact_details = JSON.parse(localStorage.getItem("contact_details"))

    localStorage.setItem("contact_details", null)

    if (contact_details) {
      contact_details.forEach(item => {
        const input = contact_form.find(`[name='${item.name}']`)
        const selectize = input[0].selectize

        if (selectize) {
          selectize.setValue(item.value)
        }
        else {
          input.val(item.value)
        }
      })
    }

    $(".clone-caller").click(function () {
      localStorage.setItem("contact_details", JSON.stringify(contact_form.serializeArray().filter(
        item => [
          "contact[yourself_or_someone_else]",
          "contact[caller_name]",
          "contact[caller_phone]",
          "contact[caller_email]"
        ].includes(item.name)
      )))

      window.open("/contacts/new", "_blank").focus()
    })

    $("[name='contact[suicidal_ideation_or_suicide_attempt]']").change(function () {
      if ($(this).val() == "Yes") {
        $(".report-crisis-button").first().trigger("click")
      }
    })
  }

  $(".steps").each(function (_index, steps) {
    steps = $(steps)
    const status_bar_items = steps.find(".steps-status-bar .steps-status-bar-item")
    const step_items = steps.find("> .step")
    const active_step = step_items.filter(".active").first()

    status_bar_items.each(function (index, item) {
      item = $(item)
      item.attr("data-step-index", index).data("step-index", index)
    })

    step_items.each(function (index, item) {
      item = $(item)
      item.attr("data-step-index", index).data("step-index", index)
    })

    on_step_change(active_step)
  })

  let next_contact_form_step_item;

  function contact_form_go_to_next() {
    go_to_step(next_contact_form_step_item)

    next_contact_form_step_item = null;
  }

  contact_form.on("ajax:success", function () {
    contact_form_go_to_next()
  })

  contact_form.on("ajax:error", function () {
    alert("Sorry! Something went wrong. Your changes weren't saved. Please try again!")
  })

  $(".steps > .step .next-step").click(function () {
    const button = $(this)
    const steps = button.closest(".steps")
    const active_step = steps.find("> .step.active")

    next_contact_form_step_item = active_step.next(".step")

    if (button.is("[data-submit]")) {
      const form = button.closest("form")

      if (!validate_form(form)) {
        return;
      }

      Rails.fire(form[0], "submit")
    }
    else {
      contact_form_go_to_next()
    }
  })

  $("[data-step-index]:not(.step)").click(function () {
    const steps = $(this).closest(".steps")
    go_to_step(steps.find("> .step").eq($(this).data("step-index")))
  })

  const map_element = $("#map")[0]

  if (map_element) {
    const input = $("#address")[0]
    const address_input = $("#address1")
    const city_input = $("#city")
    const state_input = $("#state")
    const zipcode_input = $("#zipcode")
    const lat_input = $("#lat")
    const lng_input = $("#lng")
    const myLatlng = { lat: Number(lat_input.val()) || 30.2229395, lng: Number(lng_input.val()) || -95.58035679999999 }

    const map = new google.maps.Map(map_element, {
      center: myLatlng,
      zoom: 13,
      mapTypeId: "roadmap"
    })

    const geocoder = new google.maps.Geocoder()
    // Create the search box and link it to the UI element.
    const searchBox = new google.maps.places.SearchBox(input)

    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds())
    })

    var marker = new google.maps.Marker({
      // draggable: true,
      position: myLatlng,
      map: map,
      title: "Location"
    })

    map.addListener("click", (event) => {
      marker.setPosition(event.latLng)
      map.setCenter(event.latLng)

      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      searchPosition(lat, lng)
    })

    $(input).on("keypress", function (e) {
      if (e.key !== "Enter") {
        return;
      }

      value = $(input).val()

      var ex1 = new Event("keydown")
      ex1.code = "ArrowDown"
      ex1.key = "ArrowDown"
      ex1.keyCode = 40
      google.maps.event.trigger(this, "keydown", ex1)

      var ex2 = new Event("keydown")
      ex2.code = "Enter"
      ex2.key = "Enter"
      ex2.keyCode = 13
      google.maps.event.trigger(this, "keydown", ex2)

      $(input).val(value)

      e.preventDefault()
    })

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces()
      const place = places.find(place => place.geometry)

      if (place) {
        const bounds = new google.maps.LatLngBounds()

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }

        map.fitBounds(bounds)
        marker.setPosition(place.geometry.location)

        populate_address_fields({ address: place, lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
      }
    })

    function searchPosition(lat, lng) {
      geocoder.geocode({ location: { lat, lng } }, function(results, status) {
        if (status !== "OK") {
          alert("Location Not Found")
        } else {
          const address = results[0]

          $(input).val(address.formatted_address)
          populate_address_fields({ address, lat, lng })
        }
      }.bind(this))
    }

    function populate_address_fields({ address, lat, lng }) {
      if (address) {
        const components = Object.fromEntries(address.address_components.map(component => [component.types[0], component]))
        let address1, city, state, zipcode

        if (components.route)
          address1 = [components.route.long_name, components.street_number?.long_name].filter(value => value).join(" ")
        if (components.locality)
          city = components.locality.long_name
        if (components.administrative_area_level_1)
          state = `${components.administrative_area_level_1.long_name} (${components.administrative_area_level_1.short_name})`
        if (components.postal_code)
          zipcode = components.postal_code.long_name

        address_input.val(address1)
        city_input.val(city)
        state_input.val(state)
        zipcode_input.val(zipcode)
      }

      $(input).trigger("change")

      lat_input.val(lat)
      lng_input.val(lng)
    }
  }

  const first_field_with_error = $(".field-error-message").first().closest(".field")

  if (first_field_with_error.length) {
    scroll_to(first_field_with_error)
  }
})

function rerender_sections() {
  if (window.rerender_path) {
    $.get(window.rerender_path)
  }
}

window.iframe_onload = function iframe_onload(iframe) {
  const inner_document = iframe.contentDocument
  const inner_window = iframe.contentWindow
  const resource_saved = $(inner_document.body).find("#resource_saved").length > 0
  const contact_form = $("#contact_form").first()

  if (contact_form.length && resource_saved && window.rerender_path) {
    rerender_sections()
    $("#modal").addClass("hidden")
  }
}

$(document).on("click", "[data-safe-button]", function (e) {
  const message = window.onbeforeunload()
  if (message) {
    alert(message)
    e.preventDefault()
    e.stopImmediatePropagation()
    return
  }
})

$(document).on("click", "a[href][data-ajax-target]", function (e) {
  e.preventDefault()

  const $this = $(e.currentTarget)
  const url = $this.attr("href")
  const load_into_iframe = $this.data("iframe")
  let ajax_target = $($this.data("ajax-target")).first()
  let close_modal = $this.data("prevent-cls-modal")

  if (load_into_iframe) {
    const iframe = $(`<iframe src="${url}" onLoad="window.iframe_onload(this)">`)
    ajax_target.html(iframe)
    $(".close-modal").attr('data-close-modal', true)
    return
  }

  ajax_target.html(`
    <div class="flex justify-center items-center w-full h-full">
      ${my_spinner}
    </div>
  `)

  $.ajax({
    url,
    success: function (data) {
      ajax_target.html(data)
      if(close_modal == false){
        $(".close-modal").removeAttr('data-close-modal')
      }else{
        $(".close-modal").attr('data-close-modal', true)
      }
    },
  })
})

$(document).on("click", ".sort-direction[data-direction]", function (e) {
  const $this = $(e.currentTarget)
  const direction_values = ["none", "asc", "desc"]
  const current_value = $this.attr("data-direction")

  let index = direction_values.indexOf(current_value) + 1
  if (index < 1) {
    index = 1
  }
  if (index > 2) {
    index = 0
  }

  $this.attr("data-direction", direction_values[index])
})

$(document).on("click", "[data-open-modal]", function (e) {
  $($(e.currentTarget).data("open-modal")).removeClass("hidden")
})

$(document).on("click", "[data-close-modal]", function (e) {
  $(e.currentTarget).closest(".modal").addClass("hidden")
})

$(document).on("click", "[data-modal-hide]", function (e) {
  $(e.currentTarget).closest(".modal").addClass("hidden")
})

$(document).on("click", "[data-show]", function (e) {
  $($(e.currentTarget).data("show")).show()
})

$(document).on("click", "[data-hide]", function (e) {
  $($(e.currentTarget).data("hide")).hide()
})

$(document).on("click", ".recommended-resource", function (e) {
  const $this = $(e.currentTarget)

  if ($.merge($(e.target), $(e.target).parentsUntil($this)).filter("a, .prevent-selection").length) {
    return;
  }

  $this.toggleClass("selected")

  $this.find("input").attr("disabled", !$this.hasClass("selected"))
})

$(document).on('change.remote-checkbox', 'input[type=checkbox][data-remote=true]', function () {
  var $this  = $(this)

  // Ensure value is a 1 or 0
  var value = this.checked * this.checked

  // Append value to current params
  var currentParams = $this.data('params')
  var params = (
    (currentParams ? currentParams + '&' : '') +
    $this.attr('name') + '=' + value
  )

  $this.data('params', params) .attr('disabled', true)
}).on('ajax:complete', function () {
  $(this).removeAttr('disabled')
})


$(document).on("click", ".unlock-email", function (e) {
  $("#user_email").prop('disabled', false)
})

